<!-- 我的頁面 -->
<template>
  <div class="flex py-2 border-b-2 border-gray-100 border-solid text-sm md:text-base">
    <div class="flex justify-center items-center w-2/12 md:w-1/12">
      <a-icon class="text-pink-salmon text-4xl" type="dollar" />
    </div>
    <div class="w-7/12 md:w-9/12">
      <p class="break-all" >{{ description }}</p>
      <p>{{ time }}</p>
    </div>
    <div class="w-3/12 md:w-2/12 flex items-center justify-end ">
      <span
        :class="{ 'text-pink-salmon-active': type === 1 }"
        class="font-bold md:text-lg pr-2"
        >
        {{ cash | currency | balance(type) }}
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MyCocoinItem',
  components: {},
  props: ['cash', 'description', 'time', 'type'],
  data () {
    return {

    }
  },
  // 監聽屬性 類似於data概念
  computed: {},
  // 監控data中的資料變化
  watch: {},
  // 生命週期 - 創建完成（可以訪問當前this實例）
  created () {

  },
  // 生命週期 - 掛載完成（可以訪問DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命週期 - 創建之前
  beforeMount () { }, // 生命週期 - 掛載之前
  beforeUpdate () { }, // 生命週期 - 更新之前
  updated () { }, // 生命週期 - 更新之後
  beforeDestroy () { }, // 生命週期 - 銷毀之前
  destroyed () { }, // 生命週期 - 銷毀完成
  activated () { }, // 如果頁面有keep-alive緩存功能，這個函數會觸發
  // 方法集合
  methods: {

  },
  mixins: []

}
</script>

<style lang='scss' scoped>
  p {
    @apply mb-0;
  }
</style>
