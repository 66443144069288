<template>
  <a-modal :visible="visible" :footer="null" :closable="false" :maskClosable="true" @onCancel="$emit('onClose')" centered destroyOnClose>
    <HomeAddressActions v-if="dataSource" @onBack="$emit('onClose')" :dataSource="dataSource"/>
  </a-modal>
</template>
<script>
import HomeAddressActions from './HomeAddressActions.vue'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    HomeAddressActions
  }
}
</script>