<template>
  <div class="fa-wrap">
    <!--personal 個人資料頁面內容-->
    <article class="app-page personal md:max-w-1200 md:mx-auto md:mb-14">
      <div v-if="$route.query.desc == 1">
        <div class="mt-4"><p class="text-pink-salmon text-center text-3xl"><a-icon class="mr-1" :style="{ 'vertical-align': 'text-bottom' }" type="warning" />您尚未完成註冊，請填寫以下資料</p></div>
        <br />
      </div>
      <!--錯誤訊息-->
      <div class="p-4 md:p-0">
        <header class="relative hidden md:block ">
          <h2 class="text-3xl font-bold text-center mb-8">個人資料</h2>
          <p class="text-lg -mt-3 absolute top-1/2 left-0">
            <router-link :to="{ name: 'login' }" class="flex items-center"> <a-icon type="arrow-left" class="mr-2" />回上一頁 </router-link>
          </p>
        </header>
        <div class="border-2 border-solid border-gray-300 p-0">
          <a-tabs tabBarStyle="padding: 10px" :default-active-key="activeTab">
            <a-tab-pane key="1" tab="基本資料">
              <div>
                <PersonalInfo />
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="季度回饋" force-render>
              <Dashboard />
            </a-tab-pane>
            <a-tab-pane key="3" tab="寄送地址" force-render>
              <div>
                <Address />
              </div>
            </a-tab-pane>
            <a-tab-pane key="4" tab="我的COCO幣">
              <MyCocoCoin />
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </article>
    <!--/personal 個人資料頁面內容-->
  </div>
</template>

<script>
// import Upload from '@/components/Upload.vue'
import PersonalInfo from '@/components/personal/PersonalInfo.vue'
import Address from '@/components/personal/Address.vue'
import MyCocoCoin from '@/components/personal/myCocoCoin/MyCocoCoin.vue'
import Dashboard from '@/components/personal/Dashboard.vue'

export default {
  name: 'personal',
  components: {
    PersonalInfo,
    Address,
    MyCocoCoin,
    Dashboard
  },
  data() {
    return {
    }
  },
  computed: {
    activeTab () {
      return this.$store.state.personal.activeTab || '1'
    }
  },
  methods: {
  },
  mounted() {
    this.$store.commit('REMOVE_KEY', { key: 'activeTab' })
  },
  watch: {
  },
  updated () {

  }, // 生命週期 - 更新之後

}
</script>

<style lang="scss" scoped>

  ::v-deep .ant-tabs-nav-scroll {
    border-radius: 0;
  }
  ::v-deep .ant-tabs-nav .ant-tabs-tab-active::after {
    all: unset;
  }
  ::v-deep .ant-tabs-nav > div {
  display: block;
  }
  ::v-deep .ant-tabs-nav-scroll {
    @apply text-gray-400 bg-white text-lg px-2;
  }

  ::v-deep .ant-tabs-tab-active,
  ::v-deep .ant-tabs-nav .ant-tabs-tab:hover[aria-selected="true"]{
    @apply text-pink-salmon-active; 
  }
</style>
