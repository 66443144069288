<template>
  <!--宅配-->
  <section class="bg-white mt-1">
    <div class="container-wrapper">
      <header class="flex flex-row justify-between items-center  mb-4">
        <div class="flex flex-row items-center">
          <img src="../../assets/img/logo/icon_house.svg" class="w-5 h-5 mr-2" />
          <h1 class="text-xl text-brown my-2">{{ !this.dataSource.id ? '新增地址' : '編輯地址' }}</h1>
        </div>
      </header>
      <article class="border-b mb-1 pb-2">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <div class="mb-4">
            <label class="block text-sm font-bold mb-1">收件人姓名<span class="ml-1 text-pink-salmon">*</span></label>
            <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="form.name" placeholder="請輸入姓名"></a-input>
          </div>
          <div class="mb-4">
            <label class=" text-sm font-bold mb-1 flex justify-between">
              <div><span>收件人郵件</span><span class="ml-1 text-pink-salmon">*</span></div>
              <span class="opacity-60">電子發票將郵寄至此信箱</span>
            </label>
            <a-form-model-item prop="email" ref="email">
              <a-input type="email" class="rounded-2xl bg-gray-100 ant-input" v-model="form.email" placeholder="請輸入收件人郵件"></a-input>
            </a-form-model-item>
          </div>
          <div class="mb-4">
            <label class="block text-sm font-bold mb-1">收件人電話<span class="ml-1 text-pink-salmon">*</span></label>
            <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="form.mobile" placeholder="請輸入電話"></a-input>
          </div>
          <div class="mb-4">
            <label class="block text-sm font-bold mb-1">縣市<span class="ml-1 text-pink-salmon">*</span></label>
            <twzipcode-county class="c-select" id="twzipcode__county" v-model="form.county"></twzipcode-county>
          </div>
          <div class="mb-4">
            <label class="block text-sm font-bold mb-1">鄉鎮市區<span class="ml-1 text-pink-salmon">*</span></label>
            <twzipcode-zipcode value-template=":city" text-template=":city" id="twzipcode__zipcode" class="c-select" v-model="form.district" :filter-by-county="form.county"></twzipcode-zipcode>
          </div>
          <div class="mb-4">
            <label class="block text-sm font-bold mb-1">收件地址<span class="ml-1 text-pink-salmon">*</span></label>
            <a-input class="rounded-2xl bg-gray-100 ant-input" v-model="form.detail" placeholder="請輸入您的地址"></a-input>
          </div>
        </a-form-model>
      </article>

      <footer class="w-full mt-4">
        <div class="flex flex-row justify-between">
          <button class="btn-forms btn-light w-1/2" @click="$emit('onBack')">取消</button
          ><button class="btn-forms w-1/2" :disabled="!isRequirePassed" @click="handleSubmit">確定</button>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import Zipcode from '@/components/zipcode/twzipcode-vue/zipcodes.vue'
import County from '@/components/zipcode/twzipcode-vue/counties.vue'
import { regExpEmail } from '@/utils/regExp.js'

export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ''
    }
  },
  name: 'HomeAddress',
  components: {
    TwzipcodeZipcode: Zipcode,
    TwzipcodeCounty: County
  },
  mounted() {},
  data() {
    return {
      form: {
        type: '0',
        name: '',
        email: '',
        mobile: '',
        county: '臺北市',
        district: '',
        detail: ''
      },
      rules: {
        email: { required: true, pattern: regExpEmail, message: 'Email格式不正確', trigger: 'change' }
      }
    }
  },
  methods: {
    ...mapActions(['addHomeAddress', 'editHomeAddress']),
    handleSubmit() {
      let formData = new FormData()
      let id = this.dataSource.id
      for (var key in this.form) {
        formData.append(key, this.form[key])
      }
      // Display the key/value pairs
      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      const handleCallback = () => {
          this.$emit('onBack')
      }

      !this.dataSource.id ? 
        this.addHomeAddress({ data: formData, handleSuccessCallback: handleCallback}) : this.editHomeAddress({ id, formData, handleSuccessCallback: handleCallback})
    }
  },
  computed: {
    isRequirePassed() {
      let isPass = false
      if (!isEmpty(this.form.name) & !isEmpty(this.form.mobile) & !isEmpty(this.form.county) & !isEmpty(this.form.district) & !isEmpty(this.form.detail)) {
        isPass = true
      }
      return isPass
    }
  },
  watch: {
    dataSource: {
      deep: true,
      immediate: true,
      handler(value, oldValue) {
        if (value !== oldValue) {
          if (Object.keys(value).length === 0) {
            this.form = {
              type: '0',
              name: '',
              email: '',
              mobile: '',
              county: '臺北市',
              district: '',
              detail: ''
            }
          } else {
            this.form = { ...value }
          }
        }
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.c-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  align-items: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC) no-repeat;
  background-position: calc(100% - 1rem);
  border-radius: 18px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  font-feature-settings: 'tnum';
  font-size: 14px;
  font-variant: tabular-nums;
  height: 36px;
  justify-content: center;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 4px;
  padding: 4px 11px;
  position: relative;
  transition: all 0.3s;
  width: 100%;
  box-sizing: border-box;
}
.c-select:active,
.c-select:focus {
  outline: none;
}
.ant-form-item-control {
  padding: 0;
}
.ant-row.ant-form-item {
  margin-bottom: 0;
}
</style>
