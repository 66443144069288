<template>
  <div class="h-inherit pt-12 md:h-auto md:pt-0 md:max-w-1200 md:mx-auto">
    <div class="flex flex-col h-full justify-between items-center pc-content-height">
      <section class="h-2"></section>

      <section class="flex flex-col items-center justify-center text-center">
        <header><h1 class="text-brown text-3xl mb-1">季度表現與回饋</h1></header>
        <div>{{ userBenefit.started_at }} ~ {{ userBenefit.stopped_at }}</div>
      </section>

      <section class="flex flex-col items-center justify-center">
        <article class="bg-white rounded-full flex flex-col items-center border-4 justify-center border-pink-salmon circle-size">
          <header class="text-pink-salmon text-lg mb-2">本季累積回饋金</header>
          <div class="font-sans text-pink-salmon font-bold text-4xl">{{ userBenefit.benefit }}</div>
        </article>
        <div class="font-bold text-lg mt-4 mb-1">
          <template v-if="!userBenefit.rank">您尚無購買資料故無排名</template>
          <template v-if="userBenefit.rank">您目前的排名為第 {{ userBenefit.rank }} 名</template>
        </div>
        <small>（季度購買金額前3名，回饋加成更高喔！）</small>
      </section>

      <section class="p-4 pb-0 bg-pink-salmon-300 md:mb-4">
        <h4 class="text-brown mb-2">為感謝各位團主們的支持，揪美美將從每筆銷售訂單中，提撥部分金額作為團主讀回饋金，回饋金將以 COCO 幣形式發放，發放及使用說明如下：</h4>
        <h4 class="text-brown mb-1">回饋金說明：</h4>
        <ul class="dashboard-ul text-sm">
          <li>回饋金將於商品配達後 10 日加入計算</li>
          <li>每季結算日為 03/15、06/15、09/15、12/15，發放日期為結算日之次月25日(01/25、04/25、07/25、10/25)，如遇假日則順延至下個工作日</li>
          <li>回饋金若遇小數點，將取整數(四捨五入)計算，結算日後歸零</li>
          <li>回饋金將以 COCO 幣形式發放，可用於揪美美消費時折抵使用</li>
          <!-- <li>回饋金將以 COCO 幣形式發放﹐COCO 幣使用期限為發放日起算 3 個月﹐可用於<a style="font-weight:bold" href="https://www.cocorolife.tw" target="_blank">可購樂平台</a>使用﹐COCO 幣使用說明可參閱：<a style="font-weight:bold" href="https://www.cocorolife.tw/coco" target="_blank">https://www.cocorolife.tw/coco</a></li> -->
        </ul>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'dashboard',
  data() {
    return {}
  },
  methods: {
    ...mapActions(['logout', 'getUser', 'getCartData', 'getUserBenefit']),
    handleRedirect() {
      switch (this.user.status) {
        case -1:
          this.$router.push({ name: 'personal', query: { desc: 1 } })
          break
        case 1:
          console.log('status 1')
          if (sessionStorage.getItem('afterlogin-to') != null) {
            this.$router.push(sessionStorage.getItem('afterlogin-to'))
            sessionStorage.removeItem('afterlogin-to')
          }
          // this.$router.push({ path:'/products', query: { selectedKey: 'all' } })
          break
        default:
          this.$router.push('/status')
          break
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'isLogin', 'userBenefit']),
    getUserStatus() {
      return this.user.status
    }
  },
  watch: {
    $route(to) {
      if (to.name == 'home') {
        this.handleRedirect()
      }
    }
  },
  async mounted() {
    await this.getUser()
    await this.getCartData()
    await this.getUserBenefit()
    //await this.handleRedirect()
  }
}
</script>
<style scoped lang="scss">
.circle-size {
  min-width: 220px;
  min-height: 220px;
  padding: 15px;
}
.dashboard-ul li::before {
  content: '♦ ';
}
</style>