<template>
  <div class="fa-wrap">
    <!--personal 個人資料頁面內容-->
    <article class="app-page personal md:max-w-1200 md:mx-auto md:mb-14 pt-0">
      <!-- <div v-if="$route.query.desc == 1">
        <div class="mt-4"><p class="text-pink-salmon text-center text-3xl"><a-icon class="mr-1" :style="{ 'vertical-align': 'text-bottom' }" type="warning" />您尚未完成註冊，請填寫以下資料</p></div>
        <br />
      </div> -->
      <!--錯誤訊息-->
      <div class="p-4 md:p-0">
        <section class="forms-basic">
          <a-form-model ref="registerForm" :model="form" :rules="rules" class="grid md:grid-cols-2">
            <div class="lg:mx-12 md:m-4">
              <div>
                <p class="item-title"><span>姓名</span></p>
                <a-form-model-item prop="realname">
                  <a-input type="text" placeholder="請輸入你的姓名" v-model="form.realname" />
                </a-form-model-item>
              </div>
              <div>
                <p class="item-title"><span>手機</span></p>
                <a-form-model-item prop="mobile">
                  <a-input type="text" placeholder="請輸入你的手機" :maxLength="10" v-model="form.mobile" autocomplete="nope"/>
                </a-form-model-item>
              </div>
              <!-- <div>
                <p class="item-title"><span>身分證號</span></p>
                <a-form-model-item prop="pid">
                  <a-input type="text" placeholder="請輸入你的身分證號" v-model="form.pid" />
                </a-form-model-item>
              </div> -->
              <!-- <div>
                <p class="item-title"><span>邀請碼</span></p>
                <a-form-model-item prop="realname">
                  <a-input type="text" placeholder="請輸入你的邀請碼"/>
                </a-form-model-item>
              </div> -->
            </div>
            <div class="lg:mx-12 md:m-4">
              <div>
                <p class="item-title"><span>社團 LINE 群組截圖、臉書粉專截圖</span></p>
                <a-form-model-item prop="linegroup">
                  <Upload title="請上傳群組截圖" name="linegroup" :value="form.linegroup.value" @onChange="value => handleChange('linegroup', value)" />
                </a-form-model-item>
              </div>
              <div>
                <p class="item-title"><span>(選填)存摺影本</span></p>
                <a-form-model-item prop="passbook">
                  <Upload title="請上傳存摺影本" name="passbook" :value="form.passbook.value" @onChange="value => handleChange('passbook', value)" />
                </a-form-model-item>
              </div>
            </div>
            <div class="lg:mx-12 md:m-4">
              <button class="btn-basic w-full text-md h-9" @click="toPage('changePassword')">修改密碼</button>
            </div>
            <div v-if="isVerifying && shouldMobileNotSame" class="lg:mx-12 md:m-4">
              <p class="item-title flex justify-between"><span>檢核碼</span><span class="ml-4 text-sm text-pink-salmon">檢核碼已送出,請查看簡訊</span></p>
              <a-form-model-item prop="varify" ref="varify">
                <div class="relative">
                  <a-input type="text" :maxLength="10" v-model="form.verify" placeholder="請輸入檢核碼" autocomplete="nope" />
                  <button class="custom-mobile-btn" :disabled="countDownLocked" @click="handleGetSMS">
                    <vac :left-time="60000" ref="vacCountDown">
                      <span slot="stopped">重發驗證碼</span>
                      <span slot="process" slot-scope="{ timeObj }"> {{ timeObj.ceil.s }} 秒等待 {{ getCountDownSec(timeObj.ceil.s) }} </span>
                      <span slot="finish">重發驗證碼</span>
                    </vac>
                  </button>
                </div>
              </a-form-model-item>
            </div>
            <div class="lg:mx-12 md:m-4" v-if="form.invitecode != ''">
              <p class="item-title"><span>我的邀請碼</span></p>
              <p class="text-xl">{{ form.invitecode }}
                <button class="m-1" v-clipboard:copy="form.invitecode" v-clipboard:success="handleCopied">
                  <font-awesome-icon icon="clone" />
                </button>
              </p>
            </div>
          </a-form-model>
          <small class="text-pink-salmon mx-auto table mb-2">會員資料更新，將重新審核團購主身份，審核時間約10個工作天</small>
          <section class="px-4">
            <div class="btn-forms w-full mx-0" @click="handleSubmit">
              更新資料
            </div>
          </section>
        </section>
      </div>
    </article>
    <!--/personal 個人資料頁面內容-->
  </div>
</template>

<script>
import Upload from '@/components/Upload.vue'
import { mapGetters, mapActions } from 'vuex'
import { regExpMobile } from '@/utils/regExp.js'
import message from 'ant-design-vue/es/message'

export default {
  components: {
    Upload
  },
  data() {
    return {
      form: {
        realname: '',
        mobile: '',
        pid: '',
        invitationCode: '',
        idcard1: {},
        idcard2: {},
        passbook: {},
        linegroup: {}
      },
      rules: {
        realname: { required: true, message: '請輸入姓名', trigger: 'blur' },
        mobile: { required: true, validator: (rule, value, callback) => this.validatorVerify(value, callback, '手機號碼輸入格式為 0987654321'), trigger: 'change' },
        verify: { message: '請輸入手機檢核碼', trigger: 'change' },
        // pid: { required: true, len: 10, message: '請輸入身分證字號10碼', trigger: 'blur' },
        // idcard1: { validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳身分證影本正面'), trigger: 'blur' },
        // idcard2: { validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳身分證影本反面'), trigger: 'blur' },
        // passbook: { validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳存摺影本'), trigger: 'blur' },
        linegroup: { validator: (rule, value, callback) => this.validatorPic(value, callback, '請上傳群組截圖'), trigger: 'blur' }
      },
      isVerifying: '', //手機有沒有認證並滿10數字
      countDownSec: '',
      countDownLocked: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    shouldMobileNotSame() {
      //變更手機號與原本手機號不同
      return this.user.mobile !== this.form.mobile && this.isVerifying == true
    }
  },
  methods: {
    ...mapActions(['updateUser', 'getUser', 'getVerifySMS', 'setMobileExists']),
    toPage(name) {
      this.$router.push({ name })
    },
    checkStatus() {
      if (this.user.status == -1) {
        console.log('checkStatus', this.user.status)
        this.setMobileExists(true)
        this.$router.replace({ query: { desc: 1 } }).catch(err => err) //顯示未成為團購主文字
      } else if (this.user.status !== -1) {
        this.setMobileExists(false)
        console.log('checkStatus', this.user.status)
        this.$router.replace({ query: null }).catch(err => err)
      }
    },
    getCountDownSec(sec) {
      //套件倒數的秒數
      this.countDownSec = sec
    },
    handleGetSMS() {
      // * api 取手機簡訊
      let formData = new FormData()
      let value = this.form.mobile
      console.log(value)
      formData.append('mobile', value)
      formData.append('check_exist', true)
      this.$nextTick(function() {
        this.$refs.vacCountDown.startCountdown(true)
      })
      this.getVerifySMS(formData) //取得手機驗證碼
    },
    handleBack() {
      this.$router.go(-1)
    },
    handleChange(column, { file, value }) {
      this.form[column] = {
        file,
        value
      }
      this.$refs.registerForm.validateField(column)
    },
    validatorVerify(value, callback, errorText) {
      const reg = regExpMobile
      if (!reg.test(value)) {
        this.isVerifying = false
        callback(errorText)
      } else if (reg.test(value)) this.isVerifying = true
      callback()
    },
    validatorPic(value, callback, errorText) {
      if (this.user.status == -1 && !value.value) {
        callback(errorText)
      }
      callback()
    },
    async handleSubmit() {
      this.$refs.registerForm.validate(valid => {
        if (!valid) {
          message.error(`驗證失敗，請聯繫客服 ${this.user.status}`)
          return
        }

        console.log('handleSubmit')
        const formData = new FormData()
        if (this.user.status == -1) {
          console.log('status -1: 提交全部資料')
          formData.append('realname', this.form.realname)
          formData.append('mobile', this.form.mobile)
          // formData.append('pid', this.form.pid)
          // formData.append('idcard1', this.form.idcard1.file)
          // formData.append('idcard2', this.form.idcard2.file)
          formData.append('passbook', this.form.passbook.file)
          formData.append('linegroup', this.form.linegroup.file)
        } else {
          console.log('status 非-1: 基本資料一定提交,圖片有變才送')
          formData.append('realname', this.form.realname)
          formData.append('mobile', this.form.mobile)
          // formData.append('pid', this.form.pid)
          // this.form.idcard1.file && formData.append('idcard1', this.form.idcard1.file)
          // this.form.idcard2.file && formData.append('idcard2', this.form.idcard2.file)
          this.form.passbook.file && formData.append('passbook', this.form.passbook.file)
          this.form.linegroup.file && formData.append('linegroup', this.form.linegroup.file)
        }
        if (!formData.entries().next().done) {
          this.updateUser(formData)
        }
      })
    },
    handleCopied() {
      message.success('已複製')
    },
  },
  async mounted() {
    await this.getUser()
    if( this.user.status == 0 ){
      console.log('[personal] user status 0 redirect to /status')
      this.$router.push('/status')
    }
    this.form = {
      ...this.user,
      mobile: this.user.mobile,
      // idcard1: {
      //   value: this.user.idcard1
      // },
      // idcard2: {
      //   value: this.user.idcard2
      // },
      passbook: {
        value: this.user.passbook
      },
      linegroup: {
        value: this.user.linegroup
      }
    }
  },
  watch: {
    countDownSec: {
      handler(value) {
        if (value === 1) {
          setTimeout(() => {
            this.countDownLocked = false
          }, 1000)
        } else this.countDownLocked = true
      }
    },
    isVerifying: {
      //當手機10碼符合格式,而且新號碼與舊號碼不同時
      handler(value) {
        if (value == true && this.shouldMobileNotSame == true) {
          this.handleGetSMS()
        }
      }
    },
    user: {
      deep: true,
      handler(value, oldValue) {
        if (oldValue !== value) {
          this.form = {
            ...value,
            mobile: value.mobile,
            // idcard1: {
            //   value: value.idcard1
            // },
            // idcard2: {
            //   value: value.idcard2
            // },
            passbook: {
              value: value.passbook
            },
            linegroup: {
              value: value.linegroup
            }
          }
        }
        this.$nextTick(function() {
          this.checkStatus()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-mobile-btn {
    @apply absolute right-0 top-0 rounded-2xl px-4;
    background: #fff;
    border: #513e41 1px solid;
    color: #513e41;
    height: 36px;
    line-height: 1;
  }
  .custom-mobile-btn:disabled {
    background: #e0e0e0;
    border: 0;
  }
  .custom-circle {
    padding: 0;
    width: 44px;
    height: 40px;
  }
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
</style>
