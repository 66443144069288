<template>
  <article class="">

    <!-- banner -->
    <div class="bg-pink-salmon">
      <div class="flex align-items-center justify-center">
        <h3 class="p-12 m-0 text-white text-4xl font-medium">
          <img src="@/assets/img/COCO.png" alt="" class="w-14 inline-block align-text-bottom">
          <span class="self-end">
            目前有COCO幣$
          </span>
          <span class="font-bold text-6xl ">{{ userCoco | currency }}</span>
        </h3>
      </div>
    </div>

    <!-- 分頁 -->
    <div id="coco-tabs" class="md:px-8 pb-4">
      <div class="">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="全部明細">
            <section>
              <MyCocoCoinItem
                v-for="(history, idx) in allHistory"
                :key="idx"
                v-bind="history"
              />
            </section>
          </a-tab-pane>
          <a-tab-pane key="2" tab="獲得" force-render>
            <section>
              <MyCocoCoinItem
                v-for="(history, idx) in gainHistory"
                :key="idx"
                v-bind="history"
              />
            </section>
          </a-tab-pane>
          <a-tab-pane key="3" tab="使用" force-render>
            <section>
              <MyCocoCoinItem
                v-for="(history, idx) in usedHistory"
                :key="idx"
                v-bind="history"
              />
            </section>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyCocoCoinItem from '@/components/personal/myCocoCoin/MyCocoCoinItem.vue'

export default {
  name: 'MyCocoCoin',
  components: {
    MyCocoCoinItem
  },
  data () {
    return {
      allHistory: [],
      gainHistory: [],
      usedHistory: []
    }
  },
  // 監聽屬性 類似於data概念
  computed: {
    ...mapGetters(['userCoco', 'userCocoHistory'])
  },
  // 監控data中的資料變化
  watch: {},
  // 生命週期 - 創建完成（可以訪問當前this實例）
  async created () {
    await this.getUserCoco()
    await this.getUserCocoHistory()

    this.allHistory = this.userCocoHistory
    this.gainHistory = this.userCocoHistory.filter(x => x.type === 1)
    this.usedHistory = this.userCocoHistory.filter(x => x.type === 2)

    console.log(this.userCocoHistory)
  },
  // 生命週期 - 掛載完成（可以訪問DOM元素）
  mounted () {

  },
  filters: { }, // 過濾器
  beforeCreate () { }, // 生命週期 - 創建之前
  beforeMount () { }, // 生命週期 - 掛載之前
  beforeUpdate () { }, // 生命週期 - 更新之前
  updated () { }, // 生命週期 - 更新之後
  beforeDestroy () { }, // 生命週期 - 銷毀之前
  destroyed () { }, // 生命週期 - 銷毀完成
  activated () { }, // 如果頁面有keep-alive緩存功能，這個函數會觸發
  // 方法集合
  methods: {
    ...mapActions(['getUserCoco', 'getUserCocoHistory'])
  },
  mixins: []
}
</script>

<style lang='scss' scoped>
#coco-tabs {
  ::v-deep .ant-tabs-nav-scroll {
    border-radius: 0;
  }
  ::v-deep .ant-tabs-nav .ant-tabs-tab-active::after {
    all: unset;
  }
  ::v-deep .ant-tabs-nav > div {
    display: flex;
  }
  ::v-deep .ant-tabs-tab {
    @apply w-2/6 text-center border-solid border-b-4 border-gray-400;
  }
  ::v-deep .ant-tabs-nav-scroll {
    @apply text-gray-400 bg-white text-lg px-2;
  }

  ::v-deep .ant-tabs-tab-active,
  ::v-deep .ant-tabs-nav .ant-tabs-tab:hover[aria-selected="true"]{
    @apply text-pink-salmon-active border-solid border-b-4 border-pink-salmon-active; 
  }
}
</style>
